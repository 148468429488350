import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import PanelBg1 from "@images/panel/panel-bg-01-1.png";
import PanelBg2 from "@images/panel/panel-bg-01-2.png";
import PanelBg3 from "@images/panel/panel-bg-01-3.png";
import { SLIDER_PANEL_WIDTH, SLIDER_PANEL_MAX_WIDTH } from "@models";
import "./style.scss";

const Panel = ({ children, style }) => {
  return (
    <div
      style={{
        width: SLIDER_PANEL_WIDTH,
        maxWidth: SLIDER_PANEL_MAX_WIDTH,
        ...style,
      }}>
      <div
        style={{
          width: "100%",
          backgroundImage: `url(${PanelBg1})`,
          height: "12px",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
      />
      <div
        className="panel--inner"
        style={{
          width: "100%",
          backgroundImage: `url(${PanelBg2})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "repeat",
        }}>
        {children}
      </div>
      <div
        style={{
          width: "100%",
          backgroundImage: `url(${PanelBg3})`,
          height: "50px",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
      />
    </div>
  );
};

const Grid = ({ children, row = 1 }) => <div className={`panel--grid--${row}row`}>{children}</div>;

const Title = ({ children }) => <div className="panel--title">{children}</div>;
const Content = ({ children }) => <div className="panel--content">{children}</div>;
const Tag = ({ children }) => <div className="panel--tag">{children}</div>;
const Tabs = ({ children }) => <div className="panel--tabs">{children}</div>;
const Tab = ({ active, children, onClick }) => {
  const { locale } = useIntl();
  return (
    <div className={`panel--tab ${locale} ${active ? "active" : ""}`} onClick={onClick}>
      {children}
    </div>
  );
};

Panel.Grid = Grid;
Panel.Title = Title;
Panel.Content = Content;
Panel.Tag = Tag;
Panel.Tabs = Tabs;
Panel.Tab = Tab;
export default Panel;
