import { useStaticQuery, graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

export const GROUP_BY_LIST = [
  { display: "all", key: "" },
  { display: "news", key: "news" },
  { display: "event", key: "event" },
];

export default function useNewsList() {
  const { locale } = useIntl();

  const response = useStaticQuery(graphql`
    query QueryNewsPages {
      allSitePage(
        filter: { path: { regex: "/news/./" } }
        sort: { fields: context___frontmatter___date, order: DESC }
      ) {
        nodes {
          path
          context {
            frontmatter {
              title
              date
              tag
              img
              summary
            }
            internal {
              content
            }
            intl {
              language
            }
          }
        }
      }
    }
  `);

  const { allSitePage } = response || {};
  const { nodes } = allSitePage || {};

  const newsList = nodes.filter(node => node.context.intl.language === locale);

  const groupedNewList = newsList.reduce(
    (res, el) => {
      if (!res[el.context.frontmatter.tag]) res[el.context.frontmatter.tag] = [el];
      //should not happen
      else res[el.context.frontmatter.tag].push(el);
      return res;
    },
    { news: [], event: [] }
  );

  return { newsList, groupedNewList, GROUP_BY_LIST };
}
